import React, { Component } from 'react';

class RefundAmountSVG extends Component {
    render() {
        const { height = 24, width = 24 } = this.props;
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width={width}
                height={height}
                x="0"
                y="0"
                viewBox="0 0 486 486.4"
                style={{ enableBackground: 'new 0 0 512 512' }}
                xmlSpace="preserve"
            >
                <g>
                    <path d="M252.2 0c-125.598 0-228 102.398-228 228 0 7.2 0 13.602.8 20.8l16-1.6c-.8-6.4-.8-12.802-.8-19.2 0-116.8 95.198-212 212-212s212 95.2 212 212c0 114.398-90.4 208-204 212l17.6-17.602-11.198-11.199L229 448.801l37.602 37.597L277.8 475.2 258.6 456C385 452 484.2 347.2 480.2 221.602 476.2 98.398 375.4 0 252.2 0zm0 0" fill="var(--es--svg--fill--contrast)" opacity="1" data-original="#000000" ></path>
                    <path d="M400.2 96h-288v144h288zm-16 128h-256V112h256zm0 0" fill="var(--es--svg--fill--contrast)" opacity="1" data-original="#000000" ></path>
                    <path d="M256.2 200c17.6 0 32-14.398 32-32s-14.4-32-32-32c-17.598 0-32 14.398-32 32s14.402 32 32 32zm0-48c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16 7.198-16 16-16zM160.2 144h16v-16h-32v32h16zM176.2 192h-16v-16h-16v32h32zM368.2 176h-16v16h-16v16h32zM352.2 160h16v-32h-32v16h16zM184.2 160h24v16h-24zM304.2 160h24v16h-24zM48.2 344h16v24h-16zm0 0" fill="var(--es--svg--fill--contrast)" opacity="1" data-original="#000000" ></path>
                    <path d="M400.2 327.2c0-16-13.598-29.598-29.598-29.598-2.403 0-5.602 0-8 .796l-96.801 28c-.801 0-2.403.801-3.2 0l-22.402-4c15.2-8.796 20.801-28 12.801-43.199-5.602-10.398-16-16.8-28-16.8H124.2c-4 0-8 .8-12 3.203L96.2 276v-12H.2v136h96v-20l79.198 32.8c4.801 2.4 11.204 2.4 16 .802l187.204-58.403c12.796-3.199 21.597-14.398 21.597-28zM80.2 384h-64V280h32v48h16v-48h16zm107.198 14.398c-1.597.801-4 .801-5.597 0l-85.602-36v-68l24-13.597c.801-.801 2.403-.801 4-.801h100c8.801 0 16 7.2 16 16s-7.199 16-16 16h-56v8l-1.597 8 92 15.2c3.199.8 7.199 0 10.398-.802l96.8-28c7.2-2.398 14.4 2.403 16.802 9.602 2.398 7.2-1.602 14.398-8.801 16.8zm0 0" fill="var(--es--svg--fill--contrast)" opacity="1" data-original="#000000" ></path>
                </g>
            </svg>
        )
    }
};

export default RefundAmountSVG;