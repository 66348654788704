import React from 'react';
import { connect } from 'react-redux';

class NotificationCount extends React.Component {
    constructor(props) {
        super()
        this.props = props
    }

    render() {
        const { auth, type } = this.props;
        return auth?.notificationCountList[type] ?? 0;
    }
}

const mapStateToProps = function (props) {
    return props;
};

export default connect(mapStateToProps)(NotificationCount);